import React from "react";
import { NavigationControl } from "react-map-gl";

const navControlStyle = {
  right: 10,
  top: 10,
};

export const Navigation = () => (
  <NavigationControl showCompass={false} style={navControlStyle} />
);
