import React from "react";
import styled from "styled-components";

export const MarkerIcon = styled.div`
  position: absolute;

  top: -12.5px;
  left: -12.5px;

  background-color: ${props => props.bgColor};

  width: 25px;
  height: 25px;
  border-radius: 100px;

  z-index: 10;
  cursor: pointer;

  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.15);

  @media (max-width: 767px) {
    top: -10px;
    left: -10px;

    width: 20px;
    height: 20px;
  }
`;
